@use "@angular/material" as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

/** FONT FAMILY **/
$font-family: "DMSans";
$font-family-regular: "DMSans";

/* FONTS */
@font-face {
  font-family: "DMSans";
  font-weight: 300;
  src: url(./assets/fonts/DMSans/DMSans-Regular.woff) format("woff"),
    url(./assets/fonts/DMSans/DMSans-Regular.woff2) format("woff2"),
    url(./assets/fonts/DMSans/DMSans-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "DMSans";
  font-weight: 700;
  src: url(./assets/fonts/DMSans/DMSans-Bold.woff) format("woff"),
    url(./assets/fonts/DMSans/DMSans-Bold.woff2) format("woff2"),
    url(./assets/fonts/DMSans/DMSans-Bold.ttf) format("opentype");
}

@font-face {
  font-family: "EvelethClean";
  font-weight: 300;
  src: url(./assets/fonts/EvelethClean/EvelethClean.woff) format("woff"),
    url(./assets/fonts/EvelethClean/EvelethClean.woff2) format("woff2"),
    url(./assets/fonts/EvelethClean/EvelethClean.ttf) format("opentype");
}

/** MEDIA QUERIES **/

/* GREATER THAN (min-width) */
$media-query-lt-sm: 599px;
$media-query-lt-md: 959px;
$media-query-lt-lg: 1279px;
$media-query-lt-xl: 1919px;

/* GREATER THAN (min-width) */
$media-query-gt-xs: 600px;
$media-query-gt-sm: 960px;
$media-query-gt-md: 1280px;
$media-query-gt-lg: 1920px;

/*** TABLE ***/
$table-view-height: calc(100vh - 253px);

/** HEADINGS **/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "EvelethClean", sans-serif;
}

h1 * {
  font-family: "EvelethClean", sans-serif;
}

/** ENTITIES **/

@include mat.elevation-classes();
@include mat.app-background();

$my-primary: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// TODO: Change mat global font size to 14px
$my-typography: mat.m2-define-typography-config($font-family: "DMSans"
  );

$my-theme: mat.m2-define-light-theme((color: (primary: $my-primary,
        accent: $my-accent ),
      typography: $my-typography ));

@include mat.all-component-themes($my-theme);

*,
::before,
::after {
  border-color: initial;
  outline: none !important;
}

img {
  height: initial;
}

.eveleth-clean {
  font-family: 'EvelethClean';
}

.dm-sans {
  font-family: 'DMSans';
}

/**** CONTAINER ****/

.container-wide {
  padding-left: 32px;
  padding-right: 32px;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 1.5rem;
}

::ng-deep .mat-datepicker-content {
  padding-bottom: 12px;
}

.drop-zone-container {
  border-radius: 3px;

  .intello-file-dropzone,
  .drop-zone-container__text {
    min-height: 40px;
    border: 1px dashed #736d70;
    border-radius: 3px;

    &--active {
      border: 1px dashed green;
      @apply bg-gray-50;

      .tracks__drop-zone-container__caption {
        color: green;
      }

      .tracks__drop-zone-container__sub-caption {
        color: green;
      }
    }
  }

  &__icon {
    margin-left: 0.571rem;
    /* 9.136px ml-2 */
  }

  .mat-icon:hover {
    color: #969699 !important;
  }

  &__caption {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.875rem
      /* 14px */
    ; // text-sm
    line-height: 1.25rem
      /* 20px */
    ;
    line-height: 1.43rem;
  }

  &__caption--dropped {
    color: #33ffa2;
  }

  &__sub-caption {
    font-weight: 500;
    font-size: 0.75rem
      /* 12px */
    ; // text-xs
    line-height: 1rem
      /* 16px */
    ;
    color: rgba(150, 150, 153, 1); // text-secondary-gray-60
    line-height: 1.43rem;
  }
}

.drop-zone-container--dropped {
  .kg-cms-file-dropzone {
    border-color: #33ffa2;
  }
}

.drop-zone-container .kg-cms-file-dropzone:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/*** TABLE ****/

table {
  width: 100%;
}

// TODO: Remove this hack
tr.cursor-pointer:hover {
  background-color: rgba(0, 0, 0, 0.01) !important;
}

.entity-list-table {

  .column,
  .column-heading {
    min-width: 150px !important;
    max-width: 150px;
    padding-right: 12px !important;
    padding-top: 1px;
  }

  .column-heading {
    font-size: 12px;
    text-transform: uppercase;
    color: black;
    font-weight: bold;
  }
}

.mat-mdc-header-cell {
  background-color: rgba(242, 242, 242, 1.00) !important;
}

.mdc-data-table__header-cell {
  border: 0px;
}

.paginator {
  .mat-mdc-paginator {
    background-color: transparent;
    margin-top: -8px;
    font-size: 14px;

    @media (min-width: 1281px) {
      font-size: 16px;
    }

    margin-right: -24px;
  }

  .mat-mdc-paginator-page-size {
    display: none;
  }
}

.search .mat-mdc-form-field {
  width: 100%;
}

textarea.mat-mdc-input-element.cdk-textarea-autosize {
  resize: vertical !important;
}

.search .mat-mdc-form-field-infix {
  border-top: 0px solid rgba(0, 0, 0, 0);
  font-size: 16px;
}

.search .mat-mdc-form-field-wrapper {
  padding: 0px;
}

.search .mat-mdc-form-field-underline {
  bottom: 0px;
}

.date-input .mat-mdc-form-field-infix {
  width: auto;
}

.mat-expansion-panel {
  background-color: inherit;
  box-shadow: none !important;
}

.selected-filter-text {
  font-style: italic;
}

.mat-column-select {
  left: 0px !important;
}

.kg-cms-table {
  /** BASIC STYLINGS **/

  width: 100%;
  background-color: white;

  &__row {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    &__column {
      min-width: 146px;

      padding-top: 24px;
      padding-bottom: 12px;
      padding-right: 12px;

      &__checkbox {
        width: 64px;
        min-width: 64px;
        padding-bottom: 2px;
        padding-left: 24px;

        &--loading {
          height: 18px;
          width: 18px;
        }
      }

      &__thumbnail {
        &--loading {
          height: 45px;
          margin-top: 4px;
          width: 45px;
          margin-right: 2rem;
        }
      }

      &__checkbox--higher {
        width: 64px;
        min-width: 64px;
        padding-left: 24px;

        &--loading {
          height: 18px;
          width: 18px;
        }
      }

      $titleWidths: 90, 75, 70, 60, 50, 45;

      &__title {
        @each $width in $titleWidths {
          &--loading--#{$width} {
            height: 18px;
            margin-top: -2px;
            width: $width * 1%;
          }
        }
      }

    }
  }
}

a {

  &.navigation-link {
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;

    transition-property: background-color, border-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    z-index: 100;
    border: 1px solid transparent;

    &:hover {
      color: white;
      background-color: rgba(0, 0, 0, 0.1);

      .mat-icon {
        color: white;
      }
    }

    // &:focus {
    //   background-color: $primary-dark-purple;
    // }

    .mat-icon {
      color: rgba(255, 255, 255, 0.7);
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.6);
      box-shadow: inset 8px 0px 0px #00A3C8;

      color: white;

      // font-weight: bold;
      .mat-icon {
        color: white;
      }

      &--borderless {
        background-color: inherit;
        box-shadow: none;
      }
    }

    &--logo {
      .mat-icon {
        color: white;
      }

      &.active {
        background-color: inherit;
        box-shadow: none;
      }

      &:hover {
        color: rgba(255, 255, 255, 0.4);

        .mat-icon {
          color: rgba(255, 255, 255, 0.4);
        }
      }

      // &:focus {
      //   background-color: inherit;
      // }
    }
  }

  &.dropzone {
    color: inherit;

    &:hover {
      cursor: pointer;
      color: inherit;
    }
  }
}

.mat-mdc-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
  overflow: hidden;
}

textarea {
  &.multiple-lines {
    height: auto !important;

    ::ng-deep div.mat-mdc-form-field-infix {

      // padding: .3em 0;
      textarea.mat-mdc-input-element {
        height: 19px;
      }
    }
  }
}

.mat-mdc-header-cell {
  font-size: 10px;
  line-height: 20px;

  @media (min-width: 1281px) {
    font-size: 14px;
    line-height: 22px;
  }
}

.mat-mdc-cell,
.mat-mdc-footer-cell {
  word-break: break-word;
  font-size: 12px;
  line-height: 18px;

  @media (min-width: 1281px) {
    font-size: 13px;
    line-height: 20px;
  }
}


.mat-mdc-form-field {
  div.mat-mdc-form-field-infix {

    // padding: .3em 0;
    textarea.mat-mdc-input-element {
      height: 24px;
      line-height: 20px;
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-trigger {
  width: 100%;
}

/* * HTML, BODY * */
html,
body {
  word-break: break-word;
  overflow-x: hidden;

  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 14px;
  }
}

body {
  min-height: 100vh;
}

* {
  font-family: "DMSans", sans-serif;
}

/** SIDEBAR **/
.sidebar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 32px 64px rgba(0, 0, 0, 0.1),
    0px 16px 32px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1),
    0px 4px 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
  width: 64px;

  .navigation-link {
    padding-left: 0px;

    .navigation-link__text {
      display: none;
    }
  }

  &--expanded {
    width: 205px;

    .navigation-link {
      padding-left: 1.5rem;

      .navigation-link__text {
        display: block;
      }
    }
  }

  &__container {}

  .sub-sub-navigation__list {}
}

// SAFARI GAP HANDLING
@supports (-webkit-touch-callout: none) {
  .gap-2.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 0.5rem;
  }

  .gap-3.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 0.75rem;
  }

  .gap-4.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 1rem;
  }

  .gap-5.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 1.25rem;
  }

  .gap-6.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 1.5rem;
  }

  .gap-7.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 1.75rem;
  }

  .gap-8.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 2rem;
  }

  .gap-9.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 2.25rem;
  }

  .gap-10.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 2.5rem;
  }

  .gap-11.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 2.75rem;
  }

  .gap-12.flex:not(.flex-col):not(.flex-wrap)>*:not(:last-child) {
    margin-right: 3rem;
  }

  .gap-3.flex-col>*:not(:last-child),
  .gap-3.flex-wrap>*:not(:last-child),
  .gap-3:not(.flex)>*:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  .gap-4.flex-col>*:not(:last-child),
  .gap-4.flex-wrap>*:not(:last-child),
  .gap-4:not(.flex)>*:not(:last-child) {
    margin-bottom: 1rem;
  }

  .gap-5.flex-col>*:not(:last-child),
  .gap-5.flex-wrap>*:not(:last-child),
  .gap-5:not(.flex)>*:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .gap-6.flex-col>*:not(:last-child),
  .gap-6.flex-wrap>*:not(:last-child),
  .gap-6:not(.flex)>*:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  .gap-7.flex-col>*:not(:last-child),
  .gap-7.flex-wrap>*:not(:last-child),
  .gap-7:not(.flex)>*:not(:last-child) {
    margin-bottom: 1.75rem;
  }

  .gap-8.flex-col>*:not(:last-child),
  .gap-8.flex-wrap>*:not(:last-child),
  .gap-8:not(.flex)>*:not(:last-child) {
    margin-bottom: 2rem;
  }

  .gap-9.flex-col>*:not(:last-child),
  .gap-9.flex-wrap>*:not(:last-child),
  .gap-9:not(.flex)>*:not(:last-child) {
    margin-bottom: 2.25rem;
  }

  .gap-10.flex-col>*:not(:last-child),
  .gap-10.flex-wrap>*:not(:last-child),
  .gap-10:not(.flex)>*:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  .gap-11.flex-col>*:not(:last-child),
  .gap-11.flex-wrap>*:not(:last-child),
  .gap-11:not(.flex)>*:not(:last-child) {
    margin-bottom: 2.75rem;
  }

  .gap-12.flex-col>*:not(:last-child),
  .gap-12.flex-wrap>*:not(:last-child),
  .gap-12:not(.flex)>*:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.faqs-content .mat-expansion-panel-content {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

/** INPUT FIELD OVERRIDES **/

.mat-mdc-form-field-infix {
  min-height: 40px !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.form-fields-text-container__content {
  .mat-mdc-text-field-wrapper {
    height: 42px !important;
  }
}

.mdc-evolution-chip--disabled,
.mdc-evolution-chip__action:disabled,
.mdc-text-field--disabled {
  pointer-events: all !important;
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__icon--trailing {
  color: rgba(0, 0, 0, 0.8) !important;
  opacity: 1 !important;
}

.mat-mdc-input-element,
.mat-mdc-select {
  font-size: 14px !important;
}

.form-fields-text-container__label,
.form-fields-password-container__label {
  font-size: 14px !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: var(--mdc-shape-small, 7px) !important;
  border-bottom-left-radius: var(--mdc-shape-small, 7px) !important;
}

@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: max(12px, var(--mdc-shape-small, 7px)) !important;
  }
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: var(--mdc-shape-small, 7px) !important;
  border-bottom-right-radius: var(--mdc-shape-small, 7px) !important;
}

.mdc-notched-outline__notch {
  border-right: 0px !important;
}

@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch {
    max-width: calc(100% - max(12px, var(--mdc-shape-small, 7px))*2);
  }
}

/** TOASTR **/
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

.mdc-tab__text-label {
  letter-spacing: normal !important;
}

.mdc-tab--active .mdc-tab__text-label {
  color: black !important;
}

.form-content-container {
  border-color: rgba(243, 244, 246, 1);
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.5rem
    /* 8px */
  ; // rounded-lg
}

.mat-mdc-tab-body {
  padding: 24px;
  padding-top: 6px;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--selected) .mdc-evolution-chip__action--primary:before {
  border-width: 0px !important;
}

.mdc-evolution-chip__action {
  overflow: hidden !important;
  justify-content: flex-start !important;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: unset !important;
}

.flex-auto {
  min-width: 0;
}

.large {
  font-size: 30px;
  height: 30px !important;
  width: 30px !important;
}

swiper-slide,
.swiper-wrapper {
  height: auto;
}

.drop-zone-container {

  &__title {
    font-size: 13px;
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.7);
  }

  &__text {
    font-size: 12px;

    a {
      font-size: 12px;
    }
  }
}

.form-fields-rich-text-container__content .NgxEditor {
  border-radius: 8px;
  border-color: #a7a7a7;

  &:hover {
    border-color: #212021;
  }

  ol,
  ul,
  menu {
    padding-left: 12px;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
}

.NgxEditor a {
  text-decoration: underline;
}

.mat-icon-button-small {
  padding: 1px !important;
  width: 30px !important;
  height: 30px !important;

  .mat-mdc-button-touch-target {
    width: 30px !important;
    height: 30px !important;
  }
}

.mdc-evolution-chip__action {
  cursor: pointer !important;
}

ul li a {
  text-decoration: underline;
}

/** CONTENT ELEMENTS FORM */

.apple-iphone-13 {
  width: 390px;
  height: 844px;
  padding-top: 43px;
  padding-bottom: 31px;
}

.samsung-galaxy-23 {
  width: 360px;
  height: 780px;

  padding-top: 40px;
  padding-bottom: 28px;
}

.cdk-drag-preview {
  @apply backdrop-blur-md;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.widgets.cdk-drop-list-dragging .widget:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.widget-outer-container:hover {

  .widget-actions {

    &--left,
    &--right {
      @apply block;
    }
  }
}

.widget-actions {

  &--left,
  &--right {
    @apply hidden;

  }
}

.publication-status {
  &--idea {
    border-color: rgb(11, 142, 197);
  }

  &--draft {
    border-color: rgb(237, 237, 237);
  }

  &--in-review {
    border-color: rgb(250, 183, 63);
  }

  &--published {
    border-color: rgb(29, 140, 59);
  }
}

.widgets {
  &--word-learn-goal {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    align-items: center;

    &> :nth-child(1) {
      grid-area: 1 / 1 / 4 / 2;
    }

    &> :nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }

    &> :nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
    }

    &> :nth-child(4) {
      grid-area: 3 / 2 / 4 / 3;
    }
  }
}

.mat-mdc-tab-group--inner-padding-none {
  .mat-mdc-tab-body {
    padding: 0px;
  }
}

.quizzes {
  .mat-expansion-panel-body {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-top: none;
  }

  .mat-expansion-panel-header {
    border: 1px solid rgba(0, 0, 0, 0.12);

    &.mat-expanded {
      border-bottom: none;
    }
  }

  .mat-expansion-panel-header-title {
    flex-basis: initial;
    flex-grow: initial;
  }

  .mat-expansion-panel-header-description {
    flex-grow: inherit;
    flex: auto;
  }
}

mat-icon.mat-icon.close-navigation {
  cursor: pointer;
  stroke: rgb(0, 0, 0);

  &:hover {
    stroke: rgb(59, 59, 59);
  }
}

.mat-mdc-standard-chip {
  height: auto !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: black !important;
  text-wrap: nowrap;
}